import React, { ReactElement } from "react";

import RichText from "components/RichText";
import { BodyS } from "style/components/Typography";
import { addEditAttributes } from "utils/episerver";

import { Container, Col, StyledSection } from "./Disclaimer.styled";
import DisclaimerProps from "./DisclaimerProps";
const Disclaimer = ({
    content,
    identifier,
    anchorName,
}: DisclaimerProps): ReactElement => {
    return (
        <StyledSection theme="lightgray" alignItems="center" id={anchorName}>
            <Container {...addEditAttributes("Content")}>
                <Col key={identifier}>
                    {content && (
                        <RichText content={content} renderElement={<BodyS />} />
                    )}
                </Col>
            </Container>
        </StyledSection>
    );
};

export default React.memo(Disclaimer);
