import { styled } from "styled-components";

import Section from "components/Section";
import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const StyledSection = styled(Section)`
    && {
        background-color: var(--color-white);
    }
`;

export const Col = styled.div`
    grid-column: 1 / span 4;

    ${MQ.FROM_M} {
        grid-column: col-start 5 / span 8;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 5 / span 6;
    }

    ${MQ.FROM_XL} {
        grid-column: 6 / span 5;
    }
`;

export const Container = styled(GridContainer)`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    position: relative;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;
